@import "../../CSS/Root.css";

.GameTitle{
  text-align: center;
}

.BigText{
    font-size: 10vw;
    color: var(--SexyOrange);
}

.WeeText{
    font-size: 5vw;
    color: var(--SexyOrange);
}

.AtRow{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 5%;
}

@keyframes GrowIn {
  0% {
    transform: scaleX(5%);
  }
  20% {
    transform: scaleX(5%);
  }
  100% {
    transform: scaleX(100%);
  }
}

.FlavourLine_Left {
    color: var(--SexyOrange);
    background-color: var(--SexyOrange);
    height: 0.4vw;
    width: 100%;
    animation: 2s cubic-bezier(0.12, 0.95, 0.05, 0.95) 0s 1 GrowIn;
    transform-origin: right;
    
}

.FlavourLine_Right {
    color: var(--SexyOrange);
    background-color: var(--SexyOrange);
    height: 0.4vw;
    width: 100%;
    animation: 2s cubic-bezier(0.12, 0.95, 0.05, 0.95) 0s 1 GrowIn;
    transform-origin: left;
}