@import "src/CSS/Root.css";
  
.ParagraphLayout{
    display: flex;
    text-align: left;
  }
  
  .OfficerPortrait {
    width: 15vw;
    height:25vw;
  
    border-style: solid;
    border: 5vw;
    border-color: var(--SexyOrange);
  }