@import "src/CSS/Root.css";

.Headerbar {
    position: fixed;
    display: flex;
    justify-content: space-between;

    height: 50px;
    width: 100vw;
    max-width:100%; 
    background-color: var(--SexyBlue_Low);

    border-style: none;

}

.Gradient-TopScreen {
    overflow: hidden;
    position: fixed;
    z-index: -1;
    width:100vw;
    height:30vw;

    top: 50px;

    background: rgb(0,23,31);
    background: linear-gradient(180deg, var(--SexyBlue) 25%, rgba(0,0,0,0) 100%);
}

.Headerbar-Socials {
    display: flex;
    width: auto;
    justify-content: flex-end;
}

.Headerbar-Pages {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.Headerbar-Btn {
    display: flex;
    flex: 1;
    color: var(--SexyOrange);
    height:100%;
    border-bottom-style: solid;
    border-width: 5px;
    transition-duration: 0.5s;
}

.Headerbar-Btn:hover {
    color: azure;
    transition-duration: 0.2s;
}

.Headerbar-Btn a {
    display:flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 50px;
    color: var(--SexyOrange);   
    transition-duration: 0.5s;
    width:100%;
    height:100%;
    text-align: center;
}

.Headerbar-Btn:hover a {
    color: azure;
    transition-duration: 0.2s;
}

.Icon-Socials {
    fill: var(--SexyOrange);
    width: auto;
    height: 100%;
    transition-duration: 0.5s;
    border-width: 5px;
    border-bottom-style: solid;
    border-color: var(--SexyOrange);
}

.Icon-Socials:hover {
    fill: #f0ffff;    
    color: azure;
    transition-duration: 0.2s;
    border-color: azure;   
}


/**************************************/
* {
    border-radius: 0 !important;
  }

.dropdown-toggle {
    padding: 0;
    border-width: 5px;
    border-style:none;
}

.dropdown-toggle:hover {
    border-color: green;
}

.dropdown-toggle::after {
    display: none !important; 
}

.HeaderMenu-Icon {
    background-color: transparent;
    border-style: none;
    fill: var(--SexyOrange);
    width: 64px;

    transition-duration: 0.5s;
}

.HeaderMenu-Icon:hover {
    fill: azure ;    
    transition-duration: 0.2s; 
}

.Dropdown_Header {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-end;
}

.dropdown-menu{
    background-color: var(--SexyBlue);
    padding-bottom: 0px;
}

.dropdown-menu a {
    font-size: 3rem;
    background-color: var(--SexyBlue);
    color: var(--SexyOrange);
    border-style: none;
    border-left: solid;
    transition-duration: 0.5s;
}

.dropdown-menu a:hover  {
    border-style: none;
    border-left: solid;
    border-color: azure;
    color: azure;
    background-color: var(--SexyBlue);
    transition-duration: 0.2s;
}

.dropdown-toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Dropdown_Header {
    display: none;
}

@media screen and (max-width: 768px) {

    .Headerbar {
        height: 72px;
        border-bottom: solid;
        border-color: var(--SexyOrange);
    }

    .Dropdown_Header {
      display:flex;
    }

    .Headerbar-Pages {
        display: none;
    }
  }
