@import "/src/CSS/Root.css";
  


.Button_Subpage_Background {
  position:relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width:50%;
  height:128px;
  background-color: var(--SexyOrange_Low);

  border-style: none;
  border-left: solid;
  border-width:1rem;
  border-color: var(--SexyOrange);

  margin-bottom: 1rem;
  margin-left: 1rem;

  transition-duration: 0.5s;
}

.Button_Subpage_Header{
  color: var(--SexyOrange);
  font-size: 3rem;
  margin-left: 1rem;

  transition-duration: 0.5s;
}

.Button_Subpage_Background:hover {
  border-color: azure;
  transition-duration: 0.2s;
}

.Button_Subpage_Background:hover .Button_Subpage_Header{
  color:azure;
  animation-duration: 0.2s;
}

.Button_Subpage_Background img {
  height:100%;
  width:auto;
  align-items:end;
}

.Button_Subpage_Background a {
  position:absolute;
  height:100%;
  width:100%;
  align-items:end;
}