@import "src/CSS/Root.css";

@keyframes grain {
    0%, 100% {transform: translate(0,0);}
    10% {transform: translate(-5%,-10%);}
    30% {transform: translate(3%,-15%);}
    50% {transform: translate(12%,9%);}
    70% {transform: translate(9%,4%);}
    90% {transform: translate(-1%,7%);}
  }

.PageContent{
  padding-top: 80px;
}

.ParagraphLayout{
  display: flex;
  text-align: left;
}

.OfficerPortrait {
  width: 15vw;
  height:25vw;

  border-style: solid;
  border: 5vw;
  border-color: var(--SexyOrange);
}