@import "/src/CSS/Root.css";

.ParagraphLayout {
    display: flex;
    text-align: left;
}


.DownloadButton_Header {
    display: flex;
    flex-direction: column;
    background-color: var(--SexyOrange_Low);
    margin: 1vw;
    text-align: left;
}

a {
    text-decoration: none;
}