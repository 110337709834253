@import "/src/CSS/Root.css";

.NoScroll{
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.PageContents{
  display: flex;
  flex-direction: column;
  width:100vw;
  height:100vh;
  padding-top: 80px;
}

.ErrorText{
  margin: 0;
  color: var(--SexyOrange);
  font-size: min(5vw, 40px);
  text-align: center;
}

.ErrorImage {
  object-fit:contain;
  height: 30vw;
}
