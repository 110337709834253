:root{
    --SexyBlue: #00171f;
    --SexyBlue_Low: #000c11;
    --SexyOrange: #d66f00;
    --SexyOrange_Low: #2b1600;
    --SuperBlue: #0066cd;

    font-family: 'Press Start 2P', Raleway;
}

@font-face {
    font-family: "Saniretro";
    src: local("Saniretro"),  url("/src/Fonts/Saniretro/Saniretro.ttf") format("truetype");
    font-weight: normal;
}

html body {
    font-family: Saniretro, Raleway, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: var(--SexyBlue_Low);

    margin: 0;
}

.App-header {
    display: flex;
    flex-direction: column;
}

.Paragraph-Header {
    display: flex;
    flex-direction: column;
    background-color: var(--SexyBlue_Low);
    margin:5vw;
    text-align: left;
}

.Text-Background{
    display: flex;
    background-color: var(--SexyBlue_Low);
}

h1 {
    text-align: left;
    display: inline-block;
    background-color: var(--SexyBlue);
    border-style: none;
    border-left-style: solid;
    border-width: 1vw;
    border-left-color: var(--SexyOrange);
    color: var(--SexyOrange);
    letter-spacing: 0.20rem;
    font-size: min(50px, 5vw);
    padding-left:1vw;
    margin: 0;
}

p {
    color: var(--SexyOrange);
    font-size: min(40px, 4vw);
    padding: 0px 10vw;
    padding-left:1vw;
    padding-right:1vw;
}

::-webkit-scrollbar {
    width: 10px;
}
  
::-webkit-scrollbar-track {
    background: var(--SexyBlue);
}

::-webkit-scrollbar-thumb {
    background: var(--SexyOrange_Low);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--SexyOrange);
}

.FilmGrain{
    position: fixed;
    width:200vw;
    height: 200vh;
    left: -50%;
    top: -50%;
    background-image: url('src/Resources/Texture_FilmGrain.png');
    background-repeat: repeat;
    overflow: hidden;
    animation:grain 0.5s steps(6) infinite;
    opacity: 0.05;
    pointer-events: none;
  }